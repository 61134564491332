.dma-overview-container {
  padding: 3em 10em;
  gap: 20px;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 2em 5em;
  }

  @media (max-width: 480px) {
    padding: 1em 2em;
  }
}

.dma-understanding-div {
  width: 1250px;
  max-width: calc(65% - 20px);
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  margin-right: 25px;
  background-color: white;
  padding: 0 10px;

  @media (max-width: 1200px) {
    width: 1000px;
  }
  @media (max-width: 991px) {
    flex: 1 1 100%;
    max-width: 100%;
  }

  @media (max-width: 992px) {
    width: 750px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.dma-overview-card {
  display: flex;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.dma-overview-header {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f3f3f5;
  border-radius: 1px;
  height: 50px;
  padding-left: 26px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #2e2e38;
  font-family: "EYInterstate", sans-serif;

  @media (max-width: 480px) {
    font-size: 14px;
    padding-left: 10px;
  }
}

.dma-overview-content-div {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
    padding: 10px;
  }
}

.dma-overview-row {
  display: flex;
  width: 50%;
  padding: 10px;
  cursor: pointer;

  @media (max-width: 480px) {
    flex-direction: row;
    width: 100%;
  }

  @media (max-width: 991px) {
    flex-direction: row;
    width: 100%;
  }

  @media (min-width: 992px) and (max-width: 1500px) {
    width: 75%;
  }
}

.dma-overview-number-of-reference {
  background-color: black;
  color: white;
  text-align: center;
  padding: 0px 10px;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 50px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  width: 13%;

  @media (max-width: 480px) {
    width: 30%;
  }
}

.dma-overview-content {
  background-color: #f2f3f4;
  font-size: 15px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 10px;
  display: inline-block;
  width: calc(100% - 28px);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 50%;
  }
}

.dma-overview-list {
  padding: 30px;

  @media (max-width: 480px) {
    padding: 15px;
  }
}

.dma-stakeholder-div {
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  width: 500px;
  background-color: white;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.dma-overview-row-reporting {
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 7px;
  justify-content: flex-start;
  height: 3em;
  background-color: #f2f3f4;
  color: #33333e;
  font-size: 15px;
  font-weight: 300;
  line-height: 0px;
  white-space: nowrap;
  border: none;
  &:hover {
    background-color: rgb(230, 230, 233) !important;
    color: #33333e !important;
    border: none !important;
  }

  @media (max-width: 480px) {
    flex-direction: row;
  }
}

.dma-reporting-list {
  background-color: #f2f3f4;
  font-size: 15px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 10px;
  display: inline-block;
  width: 100%;
  border-radius: 4px 0px 0px 4px;
  opacity: 1 !important;

  @media (max-width: 480px) {
    width: 50%;
  }
}

.dma-reporting-header {
  padding-right: 150px;

  @media (max-width: 480px) {
    padding-right: 50px;
  }
}

.no-underline {
  text-decoration: none;
  color: inherit;
  font-size: 15px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-underline:hover {
  text-decoration: none;
}

.dma-overview-header .context-selection-button-download {
  margin-left: auto;
}
