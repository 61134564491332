.Container{
    margin-top: 2%;
    width: 80%;
    position: absolute;
    display: inline-block;
    align-items: center;
    justify-content: center;
    left: 10%;
}
  
.new-project-footer {
    display: flex;
    justify-content: flex-end;
}
  
.button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
}
  
.cancel-button {
    background: #F3F3F5;
    margin-right: 10px;
    color: #000 !important;
}

.cancel-button:hover,.close-button:hover {
    background-color: rgb(230, 230, 233) !important;
    color: #33333e !important;
}
  
.done-button {
    background: #2E2E38;
    color: #fff;
    font-weight: 400;
}
  
.done-button:hover {
    background-color: #2E2E38;
}

.done-button .back-button-icon svg {
    color: #fff !important;
}

.new-project-card {
    width: 100% !important;
    height: 80vh !important;
    margin-top: 3%;
    margin-bottom: 3%;
    padding: 25px;
    border-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
}

.full-width {
    flex-basis: 100% !important;
    max-width: 100% !important;
}

.quarter-width {
    flex-basis: 25% !important;
    max-width: 25% !important;
}

.twenty-percent {
    flex-basis: 20% !important;
    max-width: 20% !important;
}

.thirty-percent {
    flex-basis: 30% !important;
    max-width: 30% !important;
}

.new-project-input {
    font-weight: 400 !important;
}

.error-message {
    color: red;
}

.edit-project-button-group {
    justify-content: space-between;
}

.retire-button {
    margin-left: 10px;
}