.sources-view-switch {
    padding: 2% 5%;
  }
  
  .sources-card-table-switch {
    padding: 0% 5%;
  }
  
  @media (min-width: 768px) {
    .sources-view-switch {
      padding: 2% 12%;
    }
  
    .sources-card-table-switch {
      padding: 0% 12%;
    }
  }
  
  @media (min-width: 1024px) {
    .sources-view-switch {
      padding: 2% 6%;
    }
  
    .sources-card-table-switch {
      padding: 0% 5%;
    }
  }

  