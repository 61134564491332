.gl-view-switch {
  padding: 0% 3.95%;
}

.gl-card-table-switch {
  padding: 0% 5%;
}

@media (min-width: 768px) {
  .gl-view-switch {
    padding: 2% 12%;
  }

  .gl-card-table-switch {
    padding: 0% 12%;
  }
}

@media (min-width: 1024px) {
  .gl-view-switch {
    padding: 1% 3.95%;
  }

  .gl-card-table-switch {
    padding: 0% 3.75%;
  }
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  justify-content: center;
  padding: 2px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.pagination button {
  margin: 0 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #0073e6;
  color: white;
}

