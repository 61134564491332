.value-chain-view-switch {
    padding: 2% 5%;
  }
  
  .value-chain-card-table-switch {
    padding: 0% 3.75%;
  }
  
  @media (min-width: 768px) {
    .value-chain-view-switch {
      padding: 2% 12%;
    }
  
    .value-chain-card-table-switch {
      padding: 0% 12%;
    }
  }
  
  @media (min-width: 1024px) {
    .value-chain-view-switch {
      padding: 2% 6%;
    }
  
    .value-chain-card-table-switch {
      padding: 0% 3.75%;
    }
  }
  
