// Pagination.scss
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button.active {
  background-color: #0d0d0e;
  color: white;
}

.pagination button:hover {
  background-color: #c0c0c0;
}
