.threshold-container {
  display: flex !important;
  justify-content: space-between;
  margin-top: 4%;
background: #FFFFFF;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 320px) {
    flex-direction: column;
    justify-content: center;
  }
}


