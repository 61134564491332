.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.form-group {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  flex-basis: 50%;
  max-width: 50%;
}

.form-group:last-child {
  margin-right: 0;
}

.new-project-input {
  font-family: "EYInterstate", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  border-width: thin !important;
  border-color: #ccc !important;
  border-style: solid !important;
}

.new-project-input .motif-select-input {
  border-style: none !important;
}

.field-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field-label .gis-icon svg {
  margin-left: auto;
}

.thirty-percent .field-label .motif-message,
.twenty-percent .field-label .motif-message {
  margin-top: 9px;
  line-height: 1.5;
}

.icon-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.icon-tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

.icon-tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .form-group {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    margin-right: 0;
    margin-left: 0;
  }

  .form-group {
    padding-right: 0;
    padding-left: 0;
  }

  .new-project-input {
    font-size: 14px;
    line-height: 20px;
  }

  .icon-tooltip::after {
    font-size: 10px;
    padding: 3px 6px;
  }
}

@media (max-width: 480px) {
  .field-label {
    flex-direction: column;
    align-items: flex-start;
  }

  .field-label .gis-icon svg {
    margin-left: 0;
    margin-top: 5px;
  }

  .new-project-input {
    font-size: 12px;
    line-height: 18px;
  }
}