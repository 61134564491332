.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .iro-checkbox.motif-checkbox-checked .motif-checkbox-custom {
    --checkbox--bg-color: #000000 !important;
  }
  .iro-checkbox.motif-checkbox-checked .motif-checkbox-checked-icon,
  .iro-checkbox.motif-checkbox-checked .motif-checkbox-indeterminate-icon {
    fill: #ffffff !important;
  }
  .iro-checkbox.motif-checkbox-checked .motif-checkbox-custom:hover {
    background: #000000 !important;
  }
}
  
  .modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80% !important;
    max-width: 50%;
    font-family: "EYInterstate", sans-serif;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 5px solid;
    border-color: #e8e8e8;
    border-width: thin;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 2.2rem;
    cursor: pointer;
    color: #000 !important;
  }
  
  .modal-body {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  
  .form-group {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
    flex-basis: 50%;
    max-width: 50%;
    &:last-child {
      margin-right: 0;
    }
  }
  
  .form-group-email {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
    flex-basis: 100%;
    max-width: 100%;
  }
  
  .user-modal-input {
    border-width: thin !important;
    border-color: #ccc !important;
    border-style: solid !important;
    .motif-select-input {
      border-style: none !important;
    }
  }
  
  .email-modal-input .motif-select-input {
    border-style: none !important;
    width: 100%;
  }
  
  .new-project-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  
  .cancel-button {
    background: #f3f3f5;
    margin-right: 10px;
    &:hover {
      color: #000 !important;
    }
  }
  
  .done-button {
    background: #2e2e38;
    color: #fff;
    font-weight: 400;
    &:hover {
      background-color: #2e2e38;
    }
    .back-button-icon svg {
      color: #fff !important;
    }
  }
  
  // .new-project-card {
  //   width: 100% !important;
  //   top: 20%;
  //   margin-top: 5%;
  //   margin-bottom: 5%;
  //   padding: 25px;
  //   border-color: #fff;
  //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  // }
  
  .full-width {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  
  .modal-footer {
    float: right;
    display: flex;
  }
  
  .motif-message {
    margin-bottom: 10px;
  }
  
  #gl-select-label {
    margin-top: 2% !important;
  }
  
  .iro-checkbox {
    display: flex;
    align-items: center;
    justify-content: right;
    margin: auto;
  }
  
  .search-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 2px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    min-width: 150px;
  }
  
  .filter-container {
    display: flex;
    align-items: center;
    width: 140px;
    margin-left: 10px;
    .motif-label {
      margin-right: 8px;
    }
    .filter-by-select {
      width: 100%;
    }
  }
  
  .search-box-input-iro {
    margin-left: 1rem;
    flex: 1;
    height: 32px;
  }
  
  .sidebar-item.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .sustain-modal {
      width: 90%;
      margin: auto;
      padding: 20px;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
    }
    .modal-sidebar {
      width: 100%;
      order: 2;
      margin-top: 20px;
    }
    .modal-content {
      width: 100%;
      order: 1;
    }
    .search-filter-container {
      flex-direction: column;
    }
    .search-container {
      width: 100%;
      margin-bottom: 10px;
    }
    .filter-container {
      width: 100%;
      margin-top: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .modal-header h2 {
      font-size: 1.2rem;
    }
    .modal-footer {
      flex-direction: column;
      align-items: center;
      .button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .filter-container .motif-label {
      display: none;
    }
    .filter-container .filter-by-select {
      width: 100%;
    }
  }
  
  .iro-select {
    font-weight: 400;
  }
  
  .iro-sm-container {
    max-height: 350px;
    overflow-y: auto;
  }
  
  .score-definition-label {
    margin-bottom: 20px;
    padding-left: 12px;
  }
  
  .rating-div {
    display: flex;
    width: 100%;
    border-bottom: 5px solid #e8e8e8;
    border-width: thin;
    padding: 10px 0 ;
    flex-wrap: wrap;
  }
  
  .rating-input-div {
    flex: 0 0 30%;
    margin-right: 20px;
    box-sizing: border-box;
    padding-left: 20px;
  }
  
  .rating-input-div .MotifInput {
    width: 100%;
    box-sizing: border-box;
  }
  
  .description-div {
    flex: 1;
    box-sizing: border-box;
    padding-right: 20px;
  }
  
  @media (max-width: 768px) {
    .rating-input-div,
    .description-div {
      flex: 0 0 100%;
      margin-right: 0;
    }
  }
  
  .rating-input{
    width: 210px !important;
  }
  
  .rating-input-in-box{
    width: 100% !important;
  }
  
  