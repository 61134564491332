#subheader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.Text {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

#subheader .subheader-button-container {
  width: 85%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;
}

.subheader-buttons .subheader-icon-buttons {
  display: flex !important;
}

.subheader-button-add {
  background-color: black;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px;
  border-radius: 4px;
}
.subheader-button-add:hover {
  background-color: #464646 !important;
}
.subheader-button {
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #2e2e38 !important;
}
.subheader-button:hover {
  background-color: #e6e6e9 !important;
}
.subheader-buttons .subheader-icon-buttons .motif-icon svg {
  fill: none !important;
}

.subheader-buttons {
  display: flex;
  gap: 3px;
}

.motif-text-button {
  background-color: #f2f3f4;
}

.subheader-button-container .subheader-search {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .Text {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .subheader-button-container {
    width: 85%;
    display: block !important;
  }

  .subheader-buttons .subheader-icon-buttons {
    display: flex !important;
    flex-direction: column;
    width: 100%;
  }

  .subheader-buttons {
    flex-direction: column;
  }

  .subheader-button-container .subheader-search {
    width: 80% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1244px) {
  #subheader {
    flex-direction: column;
    align-items: flex-start;
  }

  #subheader .subheader-button-container {
    justify-content: flex-start;
    width: 60%;
    flex-wrap: wrap;
  }

  #subheader .subheader-button-container .subheader-search {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .subheader-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .subheader-button-add,
  .subheader-button {
    width: 100%;
  }

  .subheader-buttons,
  .ButtonContainer {
    width: 100%;
  }
}
