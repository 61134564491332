.context-stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap; /* Ensure items don't wrap */
    padding: 1em 2em;
    font-family: 'EYInterstate', sans-serif;
  }
  
  .context-background {
    background-color: #f3f3f5;
    width: 100%;
  }
  
  .context-box-item {
    cursor: pointer;
    display: inline-block; /* Adjust size based on content */
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: #f3f3f5;
    border: none;
    padding: 10px 20px;
    margin: 0.5em; /* Adjust margin to ensure spacing */
    transition: background-color 0.3s;
    height: auto; /* Ensure height adjusts based on content */
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  .context-content {
    display: flex;
    align-items: center;
  }
  
  .context-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #2e2e38;
    font-family: 'EYInterstate', sans-serif;
  }
  
  .context-box-item.active {
    background-color: #ffffff;
    padding: 15px 25px;
  }
  
  .context-box-item:focus,
  .context-box-item:active {
    background-color: #ffffff;
    outline: none;
  }
  
  .context-section-content {
    margin-top: 1em;
    padding: 0 2em;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .context-stepper {
      padding: 1em 5em;
    }
    .context-box-item {
      margin: 0;
    }
    .context-section-content {
      padding: 0 5em;
    }
  }
  
  @media (min-width: 1024px) {
    .context-stepper {
      padding: 0em 11em;
    }
    .context-section-content {
      padding: 0 10em;
    }
  }
  
  @media (max-width: 767px) {
    .context-stepper {
      flex-wrap: wrap; /* Allow items to wrap on small screens */
      justify-content: center; /* Center items on small screens */
    }
    .context-box-item {
      flex: 1 0 100%; /* Full width on small screens */
      margin: 0.5em 0;
    }
    .context-section-content {
      padding: 0 1em;
    }
  }