.user-management-table-container {
  display: flex;
  justify-self: center;
  align-self: center;
  flex-grow: 1;
  height: 100vh;
  padding: 10px;
}

.all-user-management-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.user-management-org-container {
  margin-top: 10%;
  width: 80%;
  position: absolute;
  display: inline-block;
  align-items: center;
  justify-content: center;
  left: 10%;
  margin-bottom: 7%;
  border-radius: 7px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.user-management-org-subheader {
  padding: 10px 0px;
  border-top: 5px solid;
  border-left: 5px solid;
  border-right: 5px solid;
  border-color: #e8e8e8;
  border-width: thin;
  border-radius: 5px;
}
.user-management-loader{
  height: 130vmax;
}
