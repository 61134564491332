.tabs-container {
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 20px;
}


.tabs-header {
  display: flex;
  flex-wrap: wrap; /* Allow tabs to wrap into multiple lines */
  border: none;
  border-bottom: 1px solid #e6e6e9;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  gap: 10px; /* Add spacing between tabs */
  padding: 10px 0; /* Add some padding for better appearance */
}

.tabs-header::-webkit-scrollbar {
  display: none; /* WebKit-based browsers */
}

.tab-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #656579;
  flex: 1 1 auto; /* Allow flexible width for tabs */
  text-align: center;
}

.tab-button.active {
  font-family: "EYInterstate";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  border-bottom: 2px solid #2e2e38;
  color: #2e2e38;
}

.tro-reference-tab-name {
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #656579;

  margin: 0px;
}
.iro-reference-badge {
  margin-left: 10px;
  background: #e6e6e9;
}

.iro-reference-badge.active {
  background: #ffffff;
}

.tabs-content {
  padding: 20px;
  background-color: #fff;
  height: 35vh;
  border-radius: 0px 0px 8px 8px;
  overflow-y: auto;
}

.referenceList-title {
  background: #2e2e38;
  border-radius: 8px 8px 0px 0px;
  font-family: "EYInterstate", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #f9f9fa;
  padding: 16px;
  margin-bottom: 0px;
}

.iro-reference-list-item {
  padding: 8px 12px 8px 12px;

  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 1px !important;
  background: #ffffff;
  border: 1px solid #e6e6e9;
  font-family: "EYInterstate", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  color: #2e2e38;
}

