.Iro-file-explorer {
  background-color: white;
}
.Iro-view-switch {
  padding: 2% 5%;
}
.Iro-table {
  padding: 2em 4.25em;
  height: 80vh;
  overflow-y: auto;

  @media (max-width: 1200px) {
    padding: 2em 5.6em;
  }

  @media (max-width: 992px) {
    padding: 2em 8em;
  }

  @media (max-width: 768px) {
    padding: 2em 4em;
  }

  @media (max-width: 576px) {
    padding: 2em 2em;
  }

  @media (max-width: 400px) {
    padding: 1em 1em;
  }
}

.motif-progress-loader {
  z-index: 99999;
}
