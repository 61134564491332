#context-selection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1% 5%;
  gap: 1rem;
  font-family: "EYInterstate", sans-serif;
}

.search-box {
  display: flex;
  align-items: center;
  flex: 1;
  /* Allow search box to take available space */
  width: 650px;
  max-width: 750px;
  /* Adjust the max-width as needed */
  padding: 2px 8px;
  /* Adjust padding as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-icon {
  padding: 10px;
}

.search-box-input {
  margin-left: 1rem;
  flex: 1;
  height: 32px;
}

.context-selection-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex: 1;
}

.context-selection-button-add {
  display: flex;
  align-items: center;
  background-color: #2e2e38;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  font-family: "EYInterstate", sans-serif;
}

.context-selection-button-add:hover {
  background-color: #464646 !important;
}

.context-selection-button-download {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #2e2e38;
  border: 1px solid #2e2e38;
  padding: 0.5rem 1rem;
  margin-top: 0;
  white-space: nowrap;
}

.context-selection-button-download:hover {
  background-color: #e6e6e9 !important;
}

.context-selection-button-add .icon {
  margin-left: 0.5rem;
  align-items: center;
}

@media (min-width: 768px) {
  #context-selection {
    flex-wrap: nowrap;
    padding: 2% 12%;
  }

  .search-box {
    margin-bottom: 0;
    margin-right: 1rem;
    flex: none;
    /* Prevent search box from growing too large */
  }

  .context-selection-button-container {
    margin-top: 0;
    margin-left: 1rem;
  }
}

@media (min-width: 1024px) {
  #context-selection {
    padding: 1% 3.75%;
  }

  .context-selection-button-container {
    justify-content: flex-end;
  }
}

.library-button {
  border: 1px solid #c3c3cb;
  background: transparent;
  color: #2E2E38
}

.library-button:hover {
  background: #c3c3cb !important;
}