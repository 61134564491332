/* src/components/TreeView.css */

.tree-node {
  position: relative;
  padding-left: 20px;
}

.tree-node__label {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #2e2e38;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f9f9f9;
}

.tree-node__children {
  margin-left: 20px;
  position: relative;
}

.tree-node__children::before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  width: 1px;
  border-left: 1px solid #ccc;
}

.tree-node__children > .tree-node::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -20px;
  width: 20px;
  border-top: 1px solid #ccc;
}

.tree-item {
  position: inherit !important;
}

/*styles for minus and plus sign */
.tree-item .MuiBox-root:first-child {
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}