.context-selection-container {
  padding: 2% 9% 1% 6%;
}

.button.selected {
  background-color: #ffe600;
  color: white;
}

.esrs-checklist-assessment-subheader-section2 {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .button {
    margin: 0 0.5rem;
  }
}

.motif-row.dga-assessment {
  display: flex;
  flex-wrap: wrap;
}

.sm-file-explorer-container,
.disclosure-container,
.comment-box-container {
  width: 100%;
}

.sm-file-explorer-container {
  overflow: auto;
}

@media (min-width: 768px) {
  .sm-file-explorer-container,
  .disclosure-container {
    width: 66.66%;
  }

  .comment-box-container {
    width: 33.33%;
  }
}

@media (min-width: 1024px) {
  .context-selection-container {
    padding: 2% 6% 1% 6%;
  }

  .sm-file-explorer-container,
  .disclosure-container {
    width: 66.66%;
  }

  .comment-box-container {
    width: 33.33%;
  }
}
