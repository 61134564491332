.file-manager-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.file-container {
  margin-top: 10%;
  width: 80%;
  position: absolute;
  display: inline-block;
  align-items: center;
  justify-content: center;
  left: 10%;
  margin-bottom: 7%;
  border-radius: 7px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.file-subheader-container {
  padding: 15px;
  border-top: 5px solid;
  border-left: 5px solid;
  border-right: 5px solid;
  border-color: #e8e8e8;
  border-width: thin;
  border-radius: 5px;
}

.file-list-header {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
  padding: 1% !important;
}
.file-list-content {
  .file-name {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 20px !important;
  }
}
