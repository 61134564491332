.modal-Dga-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-Dga-modal {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 1000px;

  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-DGA-header {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.modal-DGA-body {
  display: flex;
}

.modal-DGA-sidebar {
  width: 200px;
  border-right: 1px solid #ddd;
}

.modal-DGA-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-dga-item {
  width: 100%;
  padding: 12px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.sidebar-dga-item.active {
  background: black;
  color: white;
}

.modal-dga-content {
  flex: 1;
  padding: 16px;
}

.modal-dga-footer {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.dga-button {
  margin-left: 8px;
}
