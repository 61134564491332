.motif-header-icons-placeholder .motif-icon-button {
  width: 10rem !important;
}

.motif-header-icons-placeholder svg {
  margin: 6px !important;
}
.header-motif-body {
  font-family: "EYInterstate", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: transparent !important;
  border: none !important;
  color: #656579;
}
.header-motif-body:hover {
  cursor: pointer;
  color: #656579 !important;
}

.avatar-portal {
  cursor: pointer;
}
.avatar-portal .motif-avatar-initials {
  background-color: #ffe600 !important ;
  font-weight: 600 !important;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  color: black;
  position: relative;
  border-bottom: 1px solid #e6e6e9;
}

.logo-title {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-title h1 {
  margin-left: 10px;
}

.brand-title {
  font-family: "EYInterstate", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #2e2e38;
}
.no-underline {
  text-decoration: none !important;
}
.nav-items {
  display: flex;
  align-items: center;
  margin-left: auto; /* Added to align nav items to the right */
}

.nav-items ul {
  display: flex;
  list-style: none;
}

.nav-items ul li {
  margin-left: 10px;
  margin-bottom: 0px;
}

.burger-menu {
  display: none;
  cursor: pointer;
}

.user-image {
  cursor: pointer;
  border-radius: 50%;
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 20px;
  background: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: none;
  z-index: 1000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.header-dropdown-menu ul {
  width: 7vw !important;
}
.dropdown-menu ul li {
  padding: 3px 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #656579;
}
.header-select {
  width: 100px;
}
.dropdown-menu ul li:hover {
  cursor: pointer;
}

.dropdown-menu ul li svg {
  margin-right: 10px;
}

.submenu ul li {
  padding: 2px 2px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #656579;
}

.submenu ul li:hover {
  background: #f1f1f1;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 60px;
  right: 20px;
  background: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
}

.mobile-menu.show {
  display: block;
}

.mobile-menu ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
}

.mobile-menu ul li {
  padding: 5px 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #656579;
}

.mobile-menu ul li:hover {
  background: #f1f1f1;
}
.dropdown-menu .motif-select-input {
  border: none !important;
}
.motif-select-input:hover {
  cursor: pointer !important;
}

@media (max-width: 768px) {
  .nav-items {
    display: none;
  }

  .burger-menu {
    display: block;
  }

  .right-menu {
    display: flex;
    align-items: center;
  }

  .avatar-portal {
    padding-left: 15px;
  }
}
