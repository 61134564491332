.maintenance-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 80%;
  margin-top: 2.5%;
  flex-wrap: wrap; /* Allow wrapping */
  left: 10%;
}

.maintenance-header-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: 3.5%;
  flex-wrap: wrap; /* Allow wrapping */
}

.maintenance-section1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Add margin for spacing */
}

.maintenance-section2 {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 10px; /* Add margin for spacing */
}

.active-btn {
  border: 1px solid rgba(230, 230, 233, 1);
  margin-right: 5px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.nav-left-btn {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #2e2e38;
  padding-top: 5px;
}

.modal-template {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 35%;
  max-width: 100%;
}

.all-projects-container {
  display: flex;
  flex-direction: column;
  margin-top: 3%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 5px solid;
  border-color: e8e8e8;
  border-width: thin;
}

.close-button {
  background: none;
  border: none;
  font-size: 2.2rem;
  cursor: pointer;
  color: #000 !important;
}

.change-status-modal .motif-select-input {
  padding: 0 !important;
}

.change-status-modal .motif-select-input-text {
  padding-left: 24px !important;
}

.modal-body {
  margin-bottom: 20px;
}
.modal-template-body {
  margin-bottom: 20px;
  max-height: 70vh; /* Adjust the height as needed */
  overflow-y: auto;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.form-group {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  flex-basis: 50%;
  max-width: 50%;
}

.form-template-group {
  margin-top: 3%;
  margin-bottom: 3%;
  max-width: 100%;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group-email {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  flex-basis: 100%;
  max-width: 100%;
}

.user-modal-input {
  border-width: thin !important;
  border-color: #ccc !important;
  border-style: solid !important;
}

.user-modal-input .motif-select-input {
  border-style: none !important;
}

.email-modal-input .motif-select-input {
  border-style: none !important;
  width: 100%;
}

.new-project-footer {
  display: flex;
  justify-content: flex-end;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.cancel-button {
  background: #f3f3f5;
  margin-right: 10px;
}

.cancel-button:hover,
.close-button:hover {
  background-color: rgb(230, 230, 233) !important;
  color: #33333e !important;
}

.done-button {
  background: #2e2e38;
  color: #fff;
  font-weight: 400;
}

.done-button:hover {
  background-color: #2e2e38;
}

.done-button .back-button-icon svg {
  color: #fff !important;
}

.full-width {
  flex-basis: 100% !important;
  max-width: 100% !important;
}

.modal-footer {
  float: right;
  display: flex;
}

.motif-message {
  margin-bottom: 10px;
}
.modal-overlay
  .template-checkbox.motif-checkbox-checked
  .motif-checkbox-custom {
  --checkbox--bg-color: #000000 !important;
}

.modal-overlay
  .template-checkbox.motif-checkbox-checked
  .motif-checkbox-checked-icon,
.modal-overlay
  .template-checkbox.motif-checkbox-checked
  .motif-checkbox-indeterminate-icon {
  fill: #ffffff !important;
}

.modal-overlay
  .template-checkbox.motif-checkbox-checked
  .motif-checkbox-custom:hover {
  background: #000000 !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .maintenance-container {
    width: 100%;
    left: 0;
  }

  .maintenance-header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .maintenance-section1,
  .maintenance-section2 {
    width: 100%;
    justify-content: space-between;
  }

  .maintenance-section2 {
    flex-direction: column;
    align-items: flex-start;
  }

  .maintenance-section2 .motif-button {
    width: 60%;
    margin-bottom: 10px;
  }
}
