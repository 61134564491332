.breadcrumb-inactive {
  font-family: "EYInterstate", sans-serif;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #9897a6 !important;
}
.breadcrumb-active {
  font-family: "EYInterstate", sans-serif;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #2E2E68 !important;
}
