
.client-table-container {
    display: flex;
    justify-self: center;
    align-self: center;
    flex-grow: 1;
    height: 100vh;
    padding: 10px;
  }

  .all-client-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .client-org-container{
    margin-top: 8%;
    height: 70vh;
    width: 80%;
    position: absolute;
    display: inline-block;
    align-items: center;
    justify-content: center;
    left: 10%;
    margin-bottom: 7%;
    border-radius: 7px;
    padding-bottom: 18px;
    padding-top: 8px;   
  }

  .client-org-subheader{
    padding: 12px;
    border-top: 5px solid;
    border-left:5px solid;
    border-right: 5px solid;
    border-color: 	#E8E8E8;
    border-width: thin;
    border-radius: 5px;
  }
