.card {
  width: calc(25% - 10px);
  margin-bottom: 20px;
  border: 1px solid rgba(230, 230, 233, 1);
  padding: 20px;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .card {
    width: calc(50% - 10px); /* Two cards per row */
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 100%; /* Single card per row on small screens */
  }
}

.card-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin: 0px;
  color: rgba(127, 127, 145, 1);
}

.card-value {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  margin: 5px;
}
