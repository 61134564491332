.client-table-container {
  display: flex;
  justify-self: center;
  align-self: center;
  flex-grow: 1;
  height: 100vh;
  padding: 10px;
}

.all-client-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.instance-home-client-org-container {
  height: 70vh;
  width: 80%;
  position: absolute;
  display: inline-block;
  align-items: center;
  justify-content: center;
  left: 10%;
  margin-bottom: 7%;
  border-radius: 7px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.instance-home-client-org-subheader {
  padding: 5px ;
  border-top: 5px solid;
  border-left: 5px solid;
  border-right: 5px solid;
  border-color: #e8e8e8;
  border-width: thin;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.watermark {
  position: absolute;
  top: 10em;
  left: 50%;
  font-weight: 800 !important;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: rgba(0, 0, 0, 0.1);
  text-align: center;
  pointer-events: none;
}

.version-number {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
}