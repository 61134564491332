.dms-card {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 1024px) {
    padding: 20px;
  }

  /* Generic font family added */
  font-family: 'EYInterstate', sans-serif;

  .header {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding-bottom: 2%;
  }

  .body1 {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    margin: 10px 0;
  }

  .body2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: rgba(15, 105, 174, 1);
    width: 100%;
    padding: 4px 8px;
    border-radius: 4px;
    background: rgba(244, 247, 252, 1);
    margin-top: 10px;
  }

  .motif-card-body {
    margin-top: 0;
    padding-top: 0;
    flex-grow: 1;
  }

  .motif-card-header {
    padding-left: 8%;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  .metrics {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .metric {
    display: flex;
    align-items: center;
  }

  .metric-value {
    background-color: #2e2e38;
    color: #f9f9fa;
    padding: 6px;
    border-radius: 4px 0 0 4px;
    text-align: center;
    width: 30px;
  }

  .metric-label {
    background-color: #f3f3f5;
    padding: 6px 12px;
    border-radius: 0 4px 4px 0;
  }

  .stake-holder-type {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #a04082;
    width: 100%;
    padding: 4px 8px;
    background: #faf5f8;
    margin-top: 10px;
  }

  .holder-type {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: start;
    color: #0F69AE;
    width: auto;
    padding: 4px 8px;
    background: #F4F7FC;
    margin-top: 10px;
  }

  .body-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
}
