// .modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.6);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 1000;
// }

.sustain-modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80% !important;
  max-width: 50%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px !important;
  border-bottom: 5px solid;
  border-bottom: 1px solid #E6E6E9;
  border-color: E8E8E8;
  border-width: thin;
}

.close-button {
  background: none;
  border: none;
  font-size: 2.2rem;
  cursor: pointer;
  color: #000 !important;
}

.change-status-modal .motif-select-input {
  padding: 0 !important;
}

.change-status-modal .motif-select-input-text {
  padding-left: 24px !important;
}

.modal-body {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.form-group {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  flex-basis: 50%;
  max-width: 50%;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group-email {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  flex-basis: 100%;
  max-width: 100%;
  z-index: 100;
}

.user-modal-input {
  border-width: thin !important;
  border-color: #ccc !important;
  border-style: solid !important;
}

.user-modal-input .motif-select-input {
  border-style: none !important;
}

.email-modal-input .motif-select-input {
  border-style: none !important;
  width: 100%;
}

.new-project-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1em !important;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.cancel-button {
  background: #f3f3f5;
  margin-right: 10px;
}

.cancel-button:hover,
.close-button:hover {
  background-color: rgb(230, 230, 233) !important;
  color: #33333e !important;
}

.done-button {
  background: #2e2e38;
  color: #fff;
  font-weight: 400;
}

.done-button:hover {
  background-color: #2e2e38;
}

.done-button .back-button-icon svg {
  color: #fff !important;
}

// .new-project-card {
//   width: 100% !important;
//   top: 20%;
//   margin-top: 5%;
//   margin-bottom: 5%;
//   padding: 25px;
//   border-color: #fff;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
// }

.full-width {
  flex-basis: 100% !important;
  max-width: 100% !important;
}

.modal-footer {
  float: right;
  display: flex;
}

.motif-message {
  margin-bottom: 10px;
}

#gl-select-label {
  margin-top: 2% !important;
}

.radio-group .margin-left-radio {
  margin-left: 20px;
}

.sustain-modal .modal-body {
  display: flex;
}

.modal-sidebar {
  width: 157px;
  padding: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.modal-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.modal-sidebar li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-sidebar button {
  width: 100%;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.modal-sidebar button:hover {
  background-color: #f5f5f5;
}

.modal-sidebar button.active {
  background-color: #2e2e38;
  color: #ffffff;
  font-weight: 400;
}

.sustain-modal .modal-content {
  flex: 1;
  padding-left: 20px;
}

.radio-group {
  display: flex;
  align-items: center;
  margin-top: 21px;
}

.radio-group .motif-form-field {
  flex-direction: row !important;
}

.margin-left-radio {
  margin-left: 20px;
}

.definition-section {
  background: #f4f7fc;
  padding: 10px;
  border-radius: 4px;
}

.definition-main-text {
  color: #0f69ae;
  font-weight: 400;
}

.definition-subtext {
  color: #0f69ae;
  font-weight: 300;
  font-size: 0.925em;
}

.sustain-description-label {
  margin-top: 5px;
}

.human-rights-toggle {
  margin-top: 21px;
}

.human-rights-toggle .motif-toggle-switch input:checked+.motif-toggle-switch-slider {
  background-color: #000 !important;
}
