.rateCard-container {
  border: 1px solid #e6e6e9;
  background-color: #ffffff;
  display: flex;
  padding: 5px 20px 5px 6px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #23232F14;
  box-shadow: 0px 0px 2px 0px #23232F0F;
  width: 100%;
  margin-bottom: 20px;
}

.rateCard-number-div {
  background: #e7fce8;
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px #23232f14;
  box-shadow: 0px 0px 2px 0px #23232f0f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7vh;
  width: 14%;
  margin: 10px 15px 10px 20px;
}
.rateCard-number {
  font-family: "EYInterstate", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #2e2e38;
  padding: 10px;
  margin: 0px;
}

.rateCard-header-div {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.rateCard-header {
  font-family: "EYInterstate", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #2e2e38;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.rateCard-body {
  font-family: "EYInterstate", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #197335;
  padding-top: 0px;
  margin-top: 0px;
}

.rateCard-na {
  color : grey;
}