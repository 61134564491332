.motif-card-body {
  padding: 10px 20px;
}

.threshold-header {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  background: rgba(46, 46, 56, 1);
  width: Fixed (588px) px;
  height: Hug (56px) px;
  top: 362px;
  left: 112px;
  padding: 16px 20px 16px 20px;
  gap: 10px;
  border-radius: 8px 8px 0px 0px;
}

.threshold-card {
  width: calc(50% - 10px);
  margin-bottom: 20px;
  border: 1px solid rgba(230, 230, 233, 1);
  border-radius: 5px;

  @media (max-width: 768px) {
    width: calc(90% - 10px);
  }

  @media (max-width: 320px) {
    width: calc(90% - 10px);
  }
}

.slider {
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 320px) {
    flex-direction: column;
  }
}

.threshold-input {
  width: 15% !important;
  padding: 10px 14px 10px 14px;
  border-radius: 4px;
  border: 1px solid #c3c3cb;

  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: #656579;

  @media (max-width: 768px) {
    padding: 5px 7px;
  }

  @media (max-width: 768px) {
    padding: 2px 3px;
  }
}

.slider-component {
  width: 70%;
  padding: 10px;
  padding-left: 30px;
}

.description {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  color: rgba(101, 101, 121, 1);
  background: rgba(243, 243, 245, 1);
  padding: 30px;
  border-radius: 5px;
}
