.project-details-accordion {
  top: 20%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.edit {
  float: right;
  right: 55px;
  position: absolute;
  display: flex;
  justify-content: right;
}

.motif-breadcrumb-separator .motif-icon svg {
  fill: grey;
}

.motif-breadcrumb-separator .motif-icon {
  color: grey !important;
}

#inline {
  display: flex;
  justify-content: space-between;
}

.project-details-table {
  background-color: #f2f3f4;
  margin-right: 100px;
  display: inline-block;
  vertical-align: top;
}

.project-details-table tr {
  border-bottom: 100px solid transparent;
}

.project-details-table-normal td,
.project-details-table-normal th {
  padding-right: 150px;
}

.project-details-table-admin td,
.project-details-table-admin th {
  padding-right: 125px;
}

.IconStyle {
  --icon-color: #f2f3f4;
}

.motifIcon {
  color: #0f69ae;
  fill: #0f69ae;
}

.edit-link {
  color: #0F69AE;
  bottom: 20%;
  font-size: 16px;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  font-family: "EYInterstate", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.motif-accordion-editorial .motif-accordion-content * {
  font-size: small;
  line-height: 0.7142857143rem;
}

.Accordion {
  top: 20%;
  margin-top: 5%;
}

.Link {
  color: #318ce7;
  bottom: 20%;
  font-size: 16px;
  text-decoration: none;
}

.ProjectDetails {
  background-color: #f2f3f4;
}

.project-details-header {
  font-family: "EYInterstate", sans-serif;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px !important;
  text-align: center;
  color: #2e2e38;
}

.project-details-header,
.project-details-data {
  padding-right: 150px;
  text-align: left;
}
.project-details-data {
  font-family: "EYInterstate", sans-serif;
  font-size: 16px !important;
  font-weight: 300;
  line-height: 24px !important;
  color: #2e2e38;
}

.project-user {
  font-family: "EYInterstate", sans-serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 24px !important;
  color: #2e2e38;
}

.TableData {
  padding-top: 20px;
  padding-bottom: 20px;
}

.accordion-item {
  margin-bottom: 1rem;
}

.align-top {
  vertical-align: top;
  font-family: "EYInterstate", sans-serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 24px !important;
}

.accordion-item > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.trigger-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: "EYInterstate", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #2e2e38;
}

.trigger-content .module {
  margin-left: auto;
}
