



.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: thin solid #e8e8e8;
}

.close-button {
  background: none;
  border: none;
  font-size: 2.2rem;
  cursor: pointer;
  color: #000 !important;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.cancel-button {
  background: #f3f3f5;
  margin-right: 10px;
}

.cancel-button:hover,
.close-button:hover {
  background-color: rgb(230, 230, 233) !important;
  color: #33333e !important;
}

.done-button {
  background: #2e2e38;
  color: #fff;
  font-weight: 400;
}

.delete-modal-button:hover {
  background: red;
  color: #fff;
  font-weight: 400;
}
