.context-selection-container {
  padding: 2% 9% 1% 6%;
}

.dga-assessment {
  padding: 2% 1% 5% 8%;
}

.button.selected {
  background-color: #ffe600;
  color: white;
}

.dga-assessment-subheader-section2 {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .button {
    margin: 0 0.5rem;
  }
}

.dga-toggle-button {
  width: 240px;
  height: 50px;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  margin: 0 0.5rem;
  background-color: #fff;
  color: #33333e;
  border: 1px solid #ccc;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: rgb(230, 230, 233) !important;
    color: #33333e !important;
}
}
.dga-toggle-button.selected {
  background-color: #FFE600;
  color: #2E2E38;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:18em;
  display: inline-block;
  vertical-align: middle;
}

.motif-row.dga-assessment {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 50px);
}

.sm-file-explorer-container,
.comment-box-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sm-file-explorer-container {
  overflow: auto;
}

@media (min-width: 768px) {
  .sm-file-explorer-container,
  .disclosure-container {
    width: 66.66%;
  }

  .comment-box-container {
    width: 33.33%;
  }
}

@media (min-width: 1024px) {
  .context-selection-container {
    padding: 2% 6% 1% 6%;
  }

  .sm-file-explorer-container,
  .disclosure-container {
    width: 66.66%;
  }

  .comment-box-container {
    width: 33.33%;
  }
}
