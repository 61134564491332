.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80% !important;
  max-width: 50%;
  max-height: 100vh;
  overflow-y: auto;
  font-family: "EYInterstate", sans-serif;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 5px solid;
  border-color: #e8e8e8;
  border-width: thin;
}

.close-button {
  background: none;
  border: none;
  font-size: 2.2rem;
  cursor: pointer;
  color: #000 !important;
}

.modal-body {
  margin-bottom: 20px;
  margin-top: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.form-group {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  flex-basis: 50%;
  max-width: 50%;
  &:last-child {
    margin-right: 0;
  }
}

.form-group-email {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  flex-basis: 100%;
  max-width: 100%;
}

.user-modal-input {
  border-width: thin !important;
  border-color: #ccc !important;
  border-style: solid !important;
  .motif-select-input {
    border-style: none !important;
  }
}

.email-modal-input .motif-select-input {
  border-style: none !important;
  width: 100%;
}

.new-project-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-cancel-button {
  background: #E6E6E9 ;
  color: #1a1a24;
  min-width: 5em;
  border: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px;
  border-radius: 4px;
  &:hover {
    border: none !important;
    background-color: #F3F3F5 !important;
    color: #33333e !important;
  }
}

.modal-done-button {
  background: #2e2e38;
  color: #fff;
  border: none;
  min-width: 5em;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px;
  border-radius: 4px;
  &:hover {
    border: none !important;
    background-color: #464646 !important;
    color: #fff !important;
  }
}

.modal-delete-button {
  background: #ea011d;
  width: 7em;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px;
  border-radius: 4px;
  &:hover {
    border: none !important;
    background-color: #ff4136 !important;
    color: #fff !important;
  }
}

.full-width {
  flex-basis: 100% !important;
  max-width: 100% !important;
}

.modal-footer {
  float: right;
  display: flex;
}

.motif-message {
  margin-bottom: 10px;
}

#gl-select-label {
  margin-top: 2% !important;
}

.iro-checkbox {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: auto;
}

.search-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.search-container {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 2px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  min-width: 150px;
}

.filter-container {
  display: flex;
  align-items: center;
  width: 140px;
  margin-left: 10px;
  .motif-label {
    margin-right: 8px;
  }
  .filter-by-select {
    width: 100%;
  }
}

.search-box-input-iro {
  margin-left: 1rem;
  flex: 1;
  height: 32px;
}

.sidebar-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .sustain-modal {
    width: 90%;
    margin: auto;
    padding: 20px;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
  }
  .modal-sidebar {
    width: 100%;
    order: 2;
    margin-top: 20px;
    padding-left: 15px;
  }
  .modal-content {
    width: 100%;
    order: 1;
  }
  .search-filter-container {
    flex-direction: column;
  }
  .search-container {
    width: 100%;
    margin-bottom: 10px;
  }
  .filter-container {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .modal-header h2 {
    font-size: 1.2rem;
  }
  .modal-footer {
    flex-direction: column;
    align-items: center;
    .button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .filter-container .motif-label {
    display: none;
  }
  .filter-container .filter-by-select {
    width: 100%;
  }
}

.iro-select {
  font-weight: 400;
}

.iro-sm-container {
  max-height: 350px;
  overflow-y: auto;
}

.score-definition-label {
  margin-bottom: 20px;
  padding-left: 12px;
  padding-right: 10px;
}

.rating-div {
  display: flex;
  width: 100%;
  border-bottom: 5px solid #e8e8e8;
  border-width: thin;
  padding: 10px 0;
  flex-wrap: wrap;
}

.rating-input-div {
  flex: 0 0 30%;
  margin-right: 20px;
  box-sizing: border-box;
  padding-left: 20px;
}

.rating-input-div .MotifInput {
  width: 100%;
  box-sizing: border-box;
}

.description-div {
  flex: 1;
  box-sizing: border-box;
  padding-right: 20px;
}

@media (max-width: 768px) {
  .rating-input-div,
  .description-div {
    flex: 0 0 100%;
    margin-right: 0;
  }
}

.rating-input {
  width: 210px !important;
}

.rating-input-in-box {
  width: 100% !important;
}
.modal-sidebar-score {
  width: 157px;
  border-right: 1px solid #ddd;
  padding: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 20px;
}

.modal-sidebar-score ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.modal-sidebar-score li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-sidebar-score button {
  width: 100%;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.modal-sidebar-score button:hover {
  background-color: #f5f5f5;
}

.modal-sidebar-score button.active {
  background-color: #2e2e38;
  color: #ffffff;
  font-weight: 400;
}

.rating-div-topic {
  padding-left: 2px;
}

.dma-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-buttons {
  display: flex;
  gap: 10px;
}

.formatted-description {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
}
